/*
 * Diglin GmbH - Switzerland.
 *
 * @author    Gautier Masdupuy <support@diglin.com>
 * @category  diglin
 * @copyright 2022 - Diglin (https://www.diglin.com)
 */

.home-top-image {
  width: 100%;
  height: 30rem;
  object-fit: cover;
}
