//
// user.scss
// Use this to write your custom SCSS
//

@font-face {
  font-family: 'RobotoLight';
  src: url(../fonts/roboto_v29/roboto-v29-latin-300.woff2) format('woff2');
}

@font-face {
  font-family: 'RobotoRegulare';
  src: url(../fonts/roboto_v29/roboto-v29-latin-regular.woff2) format('woff2');
}

@font-face {
  font-family: 'RobotoMedium';
  src: url(../fonts/roboto_v29/roboto-v29-latin-500.woff2) format('woff2');
}

.job-list {
  max-width: 900px;
}

.job-list .bx, .blog-article-title-section .bx, .blog-list-section .card-body .bx {
  padding-right: 0.5rem;
}

.header .dropdown .dropdown-menu {
  top: 3rem;
}

.breadcrumb {
  margin-top: 3.4rem;
  margin-bottom: 0.5rem;
  padding-left: 0.4rem;
}

.breadcrumb-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: 0.2rem;
}

.breadcrumb-item+.breadcrumb-item::before {
  padding-right: 0.2rem;
}

.blog-head-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  background-image: url(/assets/img/background/blog_background.jpg);
}

.search-box {
  display: none;
}

.search-result {
  padding: 1rem 0.5rem;
}

.search-result {
  border-bottom: 1px solid #cdcfdb;
}

.search-results a:last-child  {
  .search-result {
    border-bottom: 0;
  }
}

.search-results {
  display: none;
  position: absolute;
  background-color: #ffffff;
  width: inherit;
  border-left: 1px solid #cdcfdb;
  border-right: 1px solid #cdcfdb;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.wrapping-div {
  position: relative;
  width: inherit;
}

.search-container {
  align-self: center;
}

.search-box .bx-search {
  position: absolute;
  right: 13px;
  top: 21px;
  color: #ced4da;
}

.search-button .bx-search {
  color: #ced4da;
  vertical-align: middle;
  line-height: unset;
}

.search-button button {
  all: unset;
  cursor: pointer;
}

.wrap-link {
  text-align: start;
  white-space: normal;
}

body {
  font-family: RobotoRegulare, sans-serif;
}

.blog-2-images {
  width: 50%;
  height: fit-content;
  max-width: 21rem;
  margin-bottom: 1rem;
}

.blog-2-images .left {
  margin-right: 0.5rem;
}

.blog-article-head-image .head-text {
  display: flex;
  margin-top: 15rem;
  flex-direction: column;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.blog-head-section .head-text{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 1rem;
  text-align: center;
  max-width: 760px;
}

.about-second-section .jarallax-img {
  background-image: url(/assets/img/background/zurich_background.jpg);
}

.language-selector .left:hover, .language-selector .right:hover {
  a {
    color: $nav-link-hover-color;
  }
}

.dark-mode .language-selector svg line {
  stroke: $nav-link-dark-mode-color;
}

.language-selector svg line {
  stroke: $nav-link-color;
}

.dark-mode .language-selector li a {
  color: $nav-link-dark-mode-color;
}

.language-selector li a {
  color: $nav-link-color;
  font-weight: 600;
}


.language-selector {
  display: flex;
  flex-direction: row;
  padding-left: 0;
  margin-bottom: 0;
}

.language-selector .left{
  padding-right: 0.5rem;
  padding-left: 0;
  border: 0;
}

.language-selector .right{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  right: 0;
  border: 0;
}

.contact-form-section {
  margin-bottom: 7rem;
}

.project-section .left-column {
  padding-right: 2rem;
}

.project-section .right-column {
  padding-right: 2rem;
}

.project-section .columns-container {
  flex-direction: row;
}

.contact-section, .project-section, .blog-head-section, .solution-section, .about-first-section, .blog-article-title-section{
  margin-top: 0;
}

.calendly-popup {
  background: #ffffff;
}

.trusted-by-companies-section {
  margin-top: 4rem;
}

.trusted-by-companies-section .dark-mode .card-hover:hover {
  border-color: unset;
  background-color: unset;
}

.trusted-by-companies-section .swiper {
  height: 11rem;
}

.trusted-by-companies-section .swiper-slide .card {
  display: flex;
  justify-content: center;
}

.trusted-by-companies-section .light {
  background: #ffffff;
}

.trusted-by-companies-section .dark {
  background: $gray-900;
}

.interfaces-section img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}


.interfaces-section .small-screen {
  display: none;
}

.interfaces-section .large-screen {
  display: block;
}

.ecommerce-solutions-section .text-block {
  bottom: 0;
  color: white;
  position: absolute;
  left: 0;
  right: 0;
  width: 90%;
  height: 98%;
  margin: auto;
  top: 0;
  display: flex;
  visibility: hidden;
  align-items: center;
  justify-content: center;
  background: rgb(0 0 0);
  border-radius: 0.3rem;
  pointer-events: none;
}

.image-box:hover + .text-block, .image-box:active + .text-block{
  visibility: visible;
}

.ecommerce-solutions-section .image-box {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
}

.ecommerce-solutions-section .orocommerce {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .magento {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .shopware {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .sylius {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .akeneo {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .marello {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .orocrm {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.ecommerce-solutions-section .frontcommerce {
  background-image: url(/assets/img/landing/intro/landings/app-showcase.jpg);
}

.testimonial-company-logo {
  background-color: $body-bg;
  padding: 0.5rem;
  border-radius: 10px;
}

.testimonial-fwg .testimonial-person-picture {
  background-image: url(/assets/img/testimonials/01.jpg);
}

.testimonial-brentford .testimonial-person-picture {
  background-image: url(/assets/img/testimonials/02.jpg);
}

.testimonial-geschenkkoerbe .testimonial-person-picture {
  background-image: url(/assets/img/testimonials/03.jpg);
}

.testimonial-topaz .testimonial-person-picture {
  background-image: url(/assets/img/testimonials/01.jpg);
}

.home-services i {
  color: #f3f3f3;
}

.swiper-slide {
  display: flex;
  justify-content: center;
}

.bg-secondary {
  background-color: $body-bg !important;
}

.ml-1 {
  margin-left: 1rem;
}

.dropdown .bx-world {
  margin-left: 0.5rem;
}

.dark-mode .social-button {
  color: #FFFFFFD8;
}

.social-button .bx {
  margin-right: 0.3rem;
}

.social-button {
  color: $gray-800;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footer .social-icons {
  display: flex;
}

.social-button:hover, .social-button:focus {
  color: #768b4d;
}

.dark-mode .social-button:hover,.dark-mode .social-button:focus {
  color: #768b4d;
}

.dropdown-item {
  white-space: normal;
  font-size: 0.95rem;
}

.about-first-section {
  margin-top: 0;
  padding-bottom: 0;
}

.home-context-top {
  background-image: url(/assets/img/background/diglin_background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

a {
  text-decoration: none;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba($body-bg, var(--bs-bg-opacity)) !important;
}

.footer-links {
  padding-top: 1rem;
}

.footer-links .nav-link {
  font-weight: 100;
}

.footer .social-button {
  margin-left: 0.5rem;
}

.footer .copyright {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.copyright .legal {
  align-self: center;
}

.home-context-top {
  height: 100vh;
}

.home-context-top-container {
  height: 100vh;
}

.home-context-top-row {
  height: 100vh;
}

.home-context-top .btn-contact {
  width: 10rem;
  align-self: center;
}

.home-context-top .lead-text {
  text-align: center;
}

@media (min-width: 675px) {
  .footer-links {
    display: flex;
    width: 75%;
    justify-content: center;
    margin-top: 2rem;
  }

  .footer-links .col-lg-4 {
    width: fit-content;
  }

  .footer .copyright {
    flex-direction: row;
    align-items: unset;
  }

  .footer .social-icons {
    margin-left: auto;
  }

  .footer-links .nav-item {
    padding-left: 0;
  }

  .footer-newsletter {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .header li[class*="dropdown"] {
    display: flex;
  }

  .breadcrumb {
    margin-top: 3.6rem;
  }

  .search-container {
    padding-left: 1rem;
  }

  .language-selector {
    margin-left: 1rem;
  }

  .footer-links .col-lg-4 {
    width: 13rem;
  }

  .footer-links {
    width: 65%;
    margin-left: 0;
    padding-top: 0;
  }

  .footer-links .row {
    margin-left: auto;
  }

  .footer-newsletter {
    width: 30%;
  }
}

@media (max-height: 550px) and (min-width: 768px) {
  .home-context-top-row .lead-text {
    margin-top: 5rem;
  }
}

@media (max-width: 1063px) {
  .interfaces-section .card-body {
    max-height: 16rem;
    overflow-x: auto;
  }
}

@media (max-width: 500px) {
  .interfaces-section .card-body {
    max-height: unset;
    overflow-x: unset;
  }

  .interfaces-section .small-screen {
    display: block;
  }

  .interfaces-section .large-screen {
    display: none;
  }
}

@media (max-width: 768px) {
  .project-section .columns-container {
    flex-direction: column;
  }

  .project-section .left-column {
    padding-right: 0;
  }

  .project-section .right-column {
    padding-right: 0;
  }
}

@media (min-width: 775px) {
  .blog-article-content-section {
    max-width: 775px;
  }
}

// ***** Paralax responsive *****
@media (max-width: 767.98px) {
  .home-context-top {
    height: 25rem;
  }

  .home-context-top .btn-contact {
    width: 15rem;
    align-self: center;
  }

  .home-context-top-row {
    height: 28rem;
  }
}
